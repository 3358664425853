import Component from 'vue-class-component'
import {Watch} from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import startCase from 'lodash/startCase'
import join from 'lodash/join'
import map from 'lodash/map'
import moment from 'moment'

// Base Page
import BasePage from '@/pages/Base'

// Services
import UserSettings from '@/services/UserSettings'
import ProductService from '@/services/Products'
import CountryService from '@/services/Country'
import BannerService from '@/services/Banner'
import CategoryService from '@/services/Categories'
import Rules from '@/services/Rules'
import {param} from 'jquery'

@Component({})
export default class Banner extends BasePage {
  notify_user_role: boolean = false
  listProductsDialog: boolean = false
  listProductsObject: any = {}
  id_user: number = 0
  allTotalProducts: number = 0
  // Search
  searchLoading: boolean = false
  searchInput: string = ''
  searchLabel: string = 'Search By Name...'
  searchHint: string = ''
  searchIcon: string = 'search'
  // Table
  tableHeaders: object[] = [
    {
      text: 'Banner Name',
      align: 'left',
      sortable: true,
      value: 'name'
    },
    {
      text: 'Image',
      align: 'center',
      sortable: false,
      value: 'banner'
    },
    {
      text: 'Banner Action',
      align: 'center',
      sortable: false,
      value: 'action'
    },
    {
      text: 'Target',
      align: 'left',
      sortable: false,
      value: 'action_id'
    },
    {
      text: 'Position',
      align: 'center',
      sortable: true,
      value: 'position'
    },
    {
      text: 'Priority',
      align: 'center',
      sortable: false,
      value: 'priority'
    },
    {
      text: 'Status',
      align: 'center',
      sortable: false,
      value: 'status'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]
  tableItems: any = []
  tableTotalItems: string | number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'created_at',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = true
  bannerDialog: boolean = false
  isEdit: boolean = false
  countriesArrItem: any = []
  countriesSignItem: any = []
  bannerDataDialog: any = {
    exist_country_banners: [],
    name: null,
    status: {
      items: ['active', 'inactive'],
      selected: null
    },
    preview_image: null,
    image: null,
    priority: null,
    position: {
      items: [
        {
          text: 'Top',
          value: 'top'
        },
        {
          text: 'Middle',
          value: 'middle'
        },
        {
          text: 'Bottom',
          value: 'bottom'
        },
        {
          text: 'Middle Full',
          value: 'middle full'
        },
        {
          text: 'Modal 1',
          value: 'modal 1'
        },
        {
          text: 'Modal 2',
          value: 'modal 2'
        },
        {
          text: 'Modal 3',
          value: 'modal 3'
        },
        {
          text: 'Modal 4',
          value: 'modal 4'
        },
        {
          text: 'Modal 5',
          value: 'modal 5'
        }
      ],
      selected: null
    },
    action: {
      items: [
        {
          text: 'Open Product Page',
          value: 'product'
        },
        {
          text: 'Open Category Page',
          value: 'category'
        }, {
          text: 'Open Custom URL',
          value: 'custom'
        }
      ],
      selected: null
    },
    countrysignuser: [],
    product: {
      items: [],
      selected: null,
      loading: false,
      keyword: null
    },
    category_1: {
      items: [],
      selected: null,
      loading: false,
      keyword: null
    },
    category_2: {
      items: [],
      selected: null,
      loading: false,
      keyword: null
    },
    category_3: {
      items: [],
      selected: null,
      loading: false,
      keyword: null
    },
    custom: {
      selected: null
    },
    membership: {
      loading: false,
      keyword: null,
      items: [],
      selected: null
    }
  }
  countries: any = {
    items: [],
    keyword: null,
    loading: false,
    selected: null
  }
  selectedDelete: any = {}
  selectedId: number = 0
  showDeleteModal: boolean = false
  filter: any = {
    item: [
      {
        name: 'Active',
        id: 'active'
      },
      {
        name: 'Inactive',
        id: 'inactive'
      }
    ],
    selected: null,
    keyword: null,
    label: 'Filter Status...',
    hint: 'Apply Status Filter Here',
    icon: 'mdi-filter-variant',
    loading: false
  }
  //filter by position
  filterPosition: any = {
    item: [
      {
        name: 'Top',
        id: 'top'
      },
      {
        name: 'Middle',
        id: 'middle'
      },
      {
        name: 'Bottom',
        id: 'bottom'
      },
      {
        name: 'Middle Full',
        id: 'middle full'
      },
      {
        name: 'Modal 1',
        id: 'modal 1'
      },
      {
        name: 'Modal 2',
        id: 'modal 2'
      },
      {
        name: 'Modal 3',
        id: 'modal 3'
      },
      {
        name: 'Modal 4',
        id: 'modal 4'
      },
      {
        name: 'Modal 5',
        id: 'modal 5'
      }
    ],
    selected: null,
    keyword: null,
    label: 'Filter Position...',
    hint: 'Apply Position Filter Here',
    icon: 'mdi-filter-variant',
    loading: false
  }
  selectedCountry: string = ''
  searchProduct: Function
  searchMembership: Function
  categories_data: any = []
  opts: any = {
    params: {
      'filter[level][is]': '1',
      'include': 'child'
    }
  }
  idCategory: any = []
  viaPlaceholder: any = 's:http//via.placeholder.com/150?text=No Image Available';

  constructor() {
    super()
  }

  async mounted() {
    await this.getAllCountries()
    this.searchProduct = debounce(this.getProducts, 500)
    this.searchMembership = debounce(this.getMembership, 500)
  }

  @Watch('tablePagination', {deep: true})
  async onChanged() {
    this.$vuetify.goTo(0)
    this.getBanner()
  }

  @Watch('filter.selected')
  async onFilter() {
    this.getBanner()
  }

  @Watch('filterPosition.selected')
  async onFilterPosition() {
    this.getBanner()
  }

  @Watch('bannerDataDialog.product.keyword')
  async onSearchProduct() {
    this.searchProduct()
  }

  @Watch('bannerDataDialog.membership.keyword')
  async onSearchMember() {
    this.searchMembership()
  }

  async onClearSearch() {
    this.searchInput = ''
    this.getBanner()
  }

  async onClearFilter() {
    this.filter.selected = null
  }

  async onClearfilterPosition() {
    this.filterPosition.selected = null
  }

  async getBanner() {
    try {
      this.tableLoading = true
      const opts = {
        params: {
          'page[num]': this.tablePagination.page,
          'page[limit]': this.tablePagination.rowsPerPage,
          sort: this.tablePagination.descending ? '-' + this.tablePagination.sortBy : this.tablePagination.sortBy
        }
      }
      if (this.filter.selected) {
        var textFilter = 'filter[status][is]'
        opts.params[textFilter] = this.filter.selected.id
      } else {
        var textFilter = 'filter[status][is]'
        delete opts.params[textFilter]
      }
      if (this.filterPosition.selected) {
        var textFilter = 'filter[position][is]'
        opts.params[textFilter] = this.filterPosition.selected.id
      } else {
        var textFilter = 'filter[position][is]'
        delete opts.params[textFilter]
      }

      const response = await BannerService.getBanners(opts)
      this.tableItems = []

      forEach(response.data, banner => {

        var arr_country_banners = []
        if (Array.isArray(banner.attributes.country_banners)) {
          arr_country_banners = banner.attributes.country_banners
        } else {
          if (banner.attributes.country_banners === null) {
            arr_country_banners = []

          } else {
            arr_country_banners = banner.attributes.country_banners.split(',')
          }
        }

        var arr_country_user = []
        if (Array.isArray(banner.attributes.user_origin)) {
          arr_country_user = banner.attributes.user_origin
        } else {
          if (banner.attributes.user_origin === null) {
            arr_country_user = []

          } else {
            arr_country_user = banner.attributes.user_origin.split(',')
          }
        }

        const item = {
          ...banner.attributes,
          user_origin: arr_country_user,
          country_banners: arr_country_banners,
          banner_action: 'Open ' + startCase(banner.attributes.action_type) + ' Page',
          target: '-',
          category_selected: []
        }
        this.tableItems.push(item)
      })
      this.tablePagination.totalItems = response.meta.pagination.totalItems

      forEach(this.tableItems, async (item, index) => {
        if (item.action_type === 'product') {
          var list_target = ''
          list_target = await this.getOneProduct(item.action_id.toString())
          item.target = join(map(list_target, 'attributes.title'), ', ')
        } else if (item.action_type === 'category') {
          var target = await this.getParentCategory(item.action_id[0])
          item.target = target[0]
          item.category_selected = target[1]
        }
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  async getOneProduct(ids) {
    const opts = {
      params: {
        'filter[id][in]': ids
      }
    }
    const response = await ProductService.getProductsLists(opts)
    return response.data
  }

  async getCategories() {
    try {
      this.bannerDataDialog.category_1.loading = true
      const responseCategories = await ProductService.getTopLevelCategories(this.opts)
      const responseData = responseCategories.data.data
      this.categories_data = responseData
      this.bannerDataDialog.category_1.items = []
      forEach(responseData, categoryLevel1 => {
        var level1_item = {
          id: categoryLevel1.attributes.id,
          name: categoryLevel1.attributes.name
        }
        this.bannerDataDialog.category_1.items.push(level1_item)
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.bannerDataDialog.category_1.loading = false
    }
  }

  async getParentCategory(id) {
    const response = await CategoryService.getCategoryById(id)

    const responseCategoriesParent = await Rules.getParent(id)
    const responseIncludeCategories = responseCategoriesParent.data.included
    // list.name = responseCategoriesParent.data.data.attributes.name
    var categorynamelist = ''
    var category_selected = []
    forEach(responseIncludeCategories.parent, categoryinclude => {
      if (categoryinclude.attributes !== undefined) {
        if (categoryinclude.attributes.level == 1 && categorynamelist != '') {
          categorynamelist = categoryinclude.attributes.name + ' > ' + categorynamelist
        } else {
          categorynamelist += categoryinclude.attributes.name + ' > '
        }
        category_selected[categoryinclude.attributes.level] = {
          id: categoryinclude.attributes.id,
          name: categoryinclude.attributes.name
        }
      }
    })
    categorynamelist += response.data.data.attributes.name
    var level = response.data.data.attributes.level
    category_selected[level] = {
      id: response.data.data.attributes.id,
      name: response.data.data.attributes.name
    }
    return [categorynamelist, category_selected]
  }

  async onCategorylevel1Changed(category) {
    try {
      if (this.bannerDataDialog.category_1.selected) {
        this.bannerDataDialog.category_2.loading = true
        this.idCategory = []
        this.idCategory.push(this.bannerDataDialog.category_1.selected.id)
        const response = await ProductService.getLowLevelCategories(category.id)
        const responseData = response.data.data
        this.bannerDataDialog.category_2.items = []
        this.bannerDataDialog.category_3.items = []
        for (const categoryLevel2 of responseData) {
          if (!isEmpty(categoryLevel2.label) && categoryLevel2.level === 2)
            this.bannerDataDialog.category_2.items.push(categoryLevel2)
        }
        forEach(responseData, categoryLevel2 => {
          if (categoryLevel2.attributes.level === 2) {
            var level2_item = {
              id: categoryLevel2.attributes.id,
              name: categoryLevel2.attributes.name
            }
            this.bannerDataDialog.category_2.items.push(level2_item)
          }
        })
        // this.addCategoryRules.category_format = 'wholesale:' + lowerCase(this.bannerDataDialog.category_1.selected.name) + ':default'
      } else {
        this.bannerDataDialog.category_2.items = []
        this.bannerDataDialog.category_3.items = []
        this.bannerDataDialog.category_2.selected = null
        this.bannerDataDialog.category_3.selected = null
        this.idCategory = []
        // this.addCategoryRules.category_format = 'unknown'
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.bannerDataDialog.category_2.loading = false
    }
  }

  async onCategorylevel2Changed(category) {
    try {
      if (this.bannerDataDialog.category_2.selected) {
        this.bannerDataDialog.category_3.loading = true
        this.idCategory.push(this.bannerDataDialog.category_2.selected.id)
        const response = await ProductService.getLowLevelCategories(category.id)
        const responseData = response.data.data
        this.bannerDataDialog.category_3.items = []
        for (const categoryLevel3 of responseData) {
          if (!isEmpty(categoryLevel3.label) && categoryLevel3.level === 3)
            this.bannerDataDialog.category_3.items.push(categoryLevel3)
        }
        forEach(responseData, categoryLevel3 => {
          if (categoryLevel3.attributes.level === 3) {
            var level3_item = {
              id: categoryLevel3.attributes.id,
              name: categoryLevel3.attributes.name
            }
            this.bannerDataDialog.category_3.items.push(level3_item)
          }
        })
        // this.addCategoryRules.category_format = 'wholesale:' + lowerCase(this.bannerDataDialog.category_1.selected.name) + ':' + lowerCase(this.bannerDataDialog.category_2.selected.name) + ':default'
      } else {
        this.bannerDataDialog.category_3.items = []
        this.bannerDataDialog.category_3.selected = null
        this.idCategory.splice(1)
        // this.addCategoryRules.category_format = 'wholesale:' + lowerCase(this.bannerDataDialog.category_1.selected.name) + ':default'
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.bannerDataDialog.category_3.loading = false
    }
  }

  async onCategorylevel3Changed(category) {
    if (this.bannerDataDialog.category_3.selected) {
      this.idCategory.push(this.bannerDataDialog.category_3.selected.id)
      // this.addCategoryRules.category_format = 'wholesale:' + lowerCase(this.bannerDataDialog.category_1.selected.name) + ':' + lowerCase(this.bannerDataDialog.category_2.selected.name) + ':' + lowerCase(this.bannerDataDialog.category_3.selected.name) + ':default'
    } else {
      this.idCategory.splice(2)
      // this.addCategoryRules.category_format = 'wholesale:' + lowerCase(this.bannerDataDialog.category_1.selected.name) + ':' + lowerCase(this.bannerDataDialog.category_2.selected.name) + ':default'
    }
  }

  async getProducts() {
    try {
      this.bannerDataDialog.product.loading = true
      const opts = {
        params: {
          'page[num]': 1,
          'page[limit]': 1000,
          'include': 'country'
        }
      }

      if (!isEmpty(this.bannerDataDialog.product.keyword)) {
        var textFilter = 'filter[title][like]'
        opts.params[textFilter] = this.bannerDataDialog.product.keyword
      } else {
        var textFilter = 'filter[title][like]'
        delete opts.params[textFilter]
      }

      const response = await ProductService.getProductsLists(opts)
      this.bannerDataDialog.product.items = []
      forEach(response.data, product => {
        const item = {
          ...product.attributes,
          country: response.included.country[product.attributes.country_id].attributes.name
        }
        this.bannerDataDialog.product.items.push(item)
      })

      if (this.bannerDataDialog.product.selected) {
        this.bannerDataDialog.product.items = [...this.bannerDataDialog.product.items, ...this.bannerDataDialog.product.selected]
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.bannerDataDialog.product.loading = false
    }
  }

  async getMembership() {
    try {
      this.bannerDataDialog.membership.loading = true
      const opts = {
        params: {
          'page[num]': 1,
          'page[limit]': 25
        }
      }

      if (!isEmpty(this.bannerDataDialog.membership.keyword)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.bannerDataDialog.membership.keyword
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }

      const response = await UserSettings.getMemberships(opts)
      this.bannerDataDialog.membership.items = []
      forEach(response.data.data, membership => {
        const item = {
          id: membership.attributes.id.toString(),
          name: membership.attributes.name
        }
        this.bannerDataDialog.membership.items.push(item)
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.bannerDataDialog.membership.loading = false
    }

  }

  openCreateDialog() {
    this.getMembership()
    this.isEdit = false
    this.notify_user_role = false
    this.bannerDataDialog = {
      exist_country_banners: [],
      name: null,
      status: {
        items: ['active', 'inactive'],
        selected: null
      },
      preview_image: null,
      image: null,
      priority: null,
      position: {
        items: [
          {
            text: 'Top',
            value: 'top'
          },
          {
            text: 'Middle',
            value: 'middle'
          },
          {
            text: 'Bottom',
            value: 'bottom'
          },
          {
            text: 'Middle Full',
            value: 'middle full'
          },
          {
            text: 'Modal 1',
            value: 'modal 1'
          },
          {
            text: 'Modal 2',
            value: 'modal 2'
          },
          {
            text: 'Modal 3',
            value: 'modal 3'
          },
          {
            text: 'Modal 4',
            value: 'modal 4'
          },
          {
            text: 'Modal 5',
            value: 'modal 5'
          }
        ],
        selected: null
      },
      action: {
        items: [
          {
            text: 'Open Product Page',
            value: 'product'
          },
          {
            text: 'Open Category Page',
            value: 'category'
          }, {
            text: 'Open Custom URL',
            value: 'custom'
          }
        ],
        selected: null
      },
      countrysignuser: [],
      product: {
        items: [],
        selected: null,
        loading: false,
        keyword: null
      },
      category_1: {
        items: [],
        selected: null,
        loading: false,
        keyword: null
      },
      category_2: {
        items: [],
        selected: null,
        loading: false,
        keyword: null
      },
      category_3: {
        items: [],
        selected: null,
        loading: false,
        keyword: null
      },
      custom: {
        selected: null
      },
      membership: {
        loading: false,
        keyword: null,
        items: [],
        selected: null
      }
    }
    this.bannerDialog = true
    if ((this.$refs as any).resetData) {
      ;(this.$refs as any).resetData.value = ''
    }
    this.$validator.reset()
  }

  closeDialog() {
    this.$validator.reset()
    this.bannerDialog = false
  }

  changeImage(e) {
    const file = e.target.files[0]
    if ((file.size / 1024) < 500) {
      this.bannerDataDialog.image = file
    } else {
      this.bannerDataDialog.image = null
      ;(this.$refs as any).resetData.value = ''
      this.showSnackbar({
        text: 'File size exceeds 500 KB',
        color: 'red',
        timeout: 2000
      })
    }
  }

  onChangeAction() {
    if (this.bannerDataDialog.action.selected === 'product') {
      this.getProducts()
    } else if (this.bannerDataDialog.action.selected === 'category') {
      this.getCategories()
    }
  }

  async addBanner() {
    try {
      const validationResponse = await this.$validator.validateAll('bannerDialogScope')
      if (validationResponse) {
        if (this.bannerDataDialog.image) {
          this.showLoading({text: 'Saving...'})
          this.bannerDialog = false
          let configfile = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: 'Bearer ' + window.localStorage.getItem('access_token'),
              Identifier: 'manage'
            }
          }

          let data = new FormData()
          data.append('name', this.bannerDataDialog.name)
          data.append('banner', this.bannerDataDialog.image)
          data.append('action_type', this.bannerDataDialog.action.selected)
          data.append('status', this.bannerDataDialog.status.selected)
          data.append('priority', this.bannerDataDialog.priority)
          data.append('position', this.bannerDataDialog.position.selected)
          if (this.notify_user_role == true) {
            // data.append('membership_id[]', this.bannerDataDialog.membership.selected)
            forEach(this.bannerDataDialog.membership.selected, member => {
              data.append('membership_id[]', member)
            })
          }

          if (this.bannerDataDialog.exist_country_banners.length > 0) {
            data.append('country_banners', join(this.bannerDataDialog.exist_country_banners, ','))
          }

          if (this.bannerDataDialog.countrysignuser.length > 0) {
            data.append('user_origin[]', join(this.bannerDataDialog.countrysignuser, ','))
          }

          let ids = []
          if (this.bannerDataDialog.action.selected === 'product') {
            forEach(this.bannerDataDialog.product.selected, product => {
              data.append('action_id[]', product.id)
            })
          } else if (this.bannerDataDialog.action.selected === 'category') {
            var lastCatId = ''
            lastCatId = !isEmpty(this.idCategory) ? last(this.idCategory) : ''
            if (lastCatId) {
              data.append('action_id[]', lastCatId)
            }
          } else if (this.bannerDataDialog.action.selected === 'custom') {
            data.append('custom_url', this.bannerDataDialog.custom.selected)
            data.append('action_id[]', '0')
          }

          // if (this.bannerDataDialog.membership.selected)
          BannerService.setBanners(data).then(response => {
            this.showSnackbar({
              text: 'Saved Successfully!',
              color: 'green',
              timeout: 1500
            })
            this.getBanner()
          }).catch(error => {
            this.showSnackbar({
              text: error.response.data.message,
              color: 'red',
              timeout: 1500
            })
          })
        } else {
          this.showSnackbar({
            text: 'Please upload banner image',
            color: 'red',
            timeout: 2000
          })
        }
      } else {
        this.showSnackbar({
          text: 'Please check all fields requirements',
          color: 'red',
          timeout: 2000
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async getAllCountries() {
    try {
      var opts = {
        // params: {
        //   'filter[id][in]': arr,
        // }
        params: {
          'page[num]': 1,
          'page[limit]': 253
          // sort: this.tablePagination.descending ? '-' + this.tablePagination.sortBy : this.tablePagination.sortBy,
        }
      }

      var respCountry = await CountryService.getCountries(opts)
      var country_banners = respCountry.data.data
      var user_origin = respCountry.data.data
      // itemCountries = respCountry.data.data

      var existCountryArr = []
      forEach(country_banners, item => {
        const country = {
          id: item.attributes.id,
          name: item.attributes.name
        }
        existCountryArr.push(country)
      })

      var existCountryUser = []
      forEach(user_origin, item => {
        const country = {
          id: item.attributes.id,
          name: item.attributes.name
        }
        existCountryUser.push(country)
      })
      this.countriesArrItem = existCountryArr
      this.countriesSignItem = existCountryUser
    } catch (error) {
      this.catchHandler(error)
    }
  }

  async openEditDialog(props) {
    this.getMembership()
    var arrCountries = []
    var arrCountrySign = []

    if (!isEmpty(props.item.country_banners)) {
      var tesArr = props.item.country_banners
      tesArr.map((item) => {
        // const val = {
        //   id: Number(item)
        // }
        arrCountries.push(Number(item))
      })
    }

    if (!isEmpty(props.item.user_origin)) {
      var tesUser = props.item.user_origin
      tesUser.map((item) => {
        arrCountrySign.push(Number(item))
      })
    }
    this.isEdit = true
    this.selectedId = props.item.id
    this.notify_user_role = props.item.membership_id ? true : false
    this.bannerDataDialog = {
      exist_country_banners: arrCountries,
      name: props.item.name,
      status: {
        items: [
          {
            text: 'Active',
            value: 'active'
          },
          {
            text: 'Inactive',
            value: 'inactive'
          }
        ],
        selected: props.item.status
      },
      preview_image: props.item.url,
      image: null,
      priority: props.item.priority,
      position: {
        items: [
          {
            text: 'Top',
            value: 'top'
          },
          {
            text: 'Middle',
            value: 'middle'
          },
          {
            text: 'Bottom',
            value: 'bottom'
          },
          {
            text: 'Middle Full',
            value: 'middle full'
          },
          {
            text: 'Modal 1',
            value: 'modal 1'
          },
          {
            text: 'Modal 2',
            value: 'modal 2'
          },
          {
            text: 'Modal 3',
            value: 'modal 3'
          },
          {
            text: 'Modal 4',
            value: 'modal 4'
          },
          {
            text: 'Modal 5',
            value: 'modal 5'
          }
        ],
        selected: props.item.position
      },
      action: {
        items: [
          {
            text: 'Open Product Page',
            value: 'product'
          },
          {
            text: 'Open Category Page',
            value: 'category'
          },
          {
            text: 'Open Custom URL',
            value: 'custom'
          }
        ],
        selected: props.item.action_type
      },
      countrysignuser: arrCountrySign,
      membership: {
        loading: false,
        keyword: null,
        items: [],
        selected: props.item.membership_id
      },
      product: {
        items: [],
        selected: null,
        loading: false,
        keyword: null
      },
      category_1: {
        items: [],
        selected: null,
        loading: false,
        keyword: null
      },
      category_2: {
        items: [],
        selected: null,
        loading: false,
        keyword: null
      },
      category_3: {
        items: [],
        selected: null,
        loading: false,
        keyword: null
      },
      custom: {
        selected: null
      }
    }
    this.bannerDialog = true
    this.$validator.reset()
    if ((this.$refs as any).resetData) {
      ;(this.$refs as any).resetData.value = ''
    }


    if (props.item.action_type === 'product') {
      this.getProducts()
      var product = await this.getOneProduct(props.item.action_id.toString())
      this.bannerDataDialog.product.selected = map(product, 'attributes')
    } else if (props.item.action_type === 'category') {
      this.getCategories()
      this.bannerDataDialog.category_1.selected = props.item.category_selected[1]
      await this.onCategorylevel1Changed(props.item.category_selected[1])
      this.bannerDataDialog.category_2.selected = props.item.category_selected[2]
      await this.onCategorylevel2Changed(props.item.category_selected[2])
      this.bannerDataDialog.category_3.selected = props.item.category_selected[3]
      await this.onCategorylevel3Changed(props.item.category_selected[3])
    } else {
      this.bannerDataDialog.custom.selected = props.item.custom_url
    }
  }

  async updateBanner() {
    try {
      const validationResponse = await this.$validator.validateAll('bannerDialogScope')
      if (validationResponse) {
        this.showLoading({text: 'Saving...'})
        this.bannerDialog = false
        let configfile = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + window.localStorage.getItem('access_token'),
            Identifier: 'manage'
          }
        }
        let data = new FormData()
        data.append('name', this.bannerDataDialog.name)
        if (this.bannerDataDialog.image) {
          data.append('banner', this.bannerDataDialog.image)
        }
        data.append('action_type', this.bannerDataDialog.action.selected)
        data.append('status', this.bannerDataDialog.status.selected)
        data.append('priority', this.bannerDataDialog.priority)
        data.append('position', this.bannerDataDialog.position.selected)
        if (this.notify_user_role == true) {
          // data.append('membership_id[]', this.bannerDataDialog.membership.selected)
          forEach(this.bannerDataDialog.membership.selected, member => {
            data.append('membership_id[]', member)
          })
        }

        if (this.bannerDataDialog.exist_country_banners.length > 0) {
          data.append('country_banners', join(this.bannerDataDialog.exist_country_banners, ','))
        }

        if (this.bannerDataDialog.countrysignuser.length > 0) {
          data.append('user_origin', join(this.bannerDataDialog.countrysignuser, ','))
        }

        let ids = []
        if (this.bannerDataDialog.action.selected === 'product') {
          forEach(this.bannerDataDialog.product.selected, product => {
            data.append('action_id[]', product.id)
          })
        } else if (this.bannerDataDialog.action.selected === 'category') {
          var lastCatId = ''
          lastCatId = !isEmpty(this.idCategory) ? last(this.idCategory) : ''
          if (lastCatId) {
            data.append('action_id[]', lastCatId)
          }
        } else if (this.bannerDataDialog.action.selected === 'custom') {
          data.append('custom_url', this.bannerDataDialog.custom.selected)
          data.append('action_id[]', '0')
        }

        BannerService.editBanners(this.selectedId, data).then(response => {
          this.showSnackbar({
            text: 'Saved Successfully!',
            color: 'green',
            timeout: 1500
          })
          this.getBanner()
        }).catch(error => {
          this.showSnackbar({
            text: error.response.data.message,
            color: 'red',
            timeout: 1500
          })
        })
      } else {
        this.showSnackbar({
          text: 'Please check all fields requirements',
          color: 'red',
          timeout: 2000
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  openDeleteModal(props) {
    this.selectedDelete = props.item.id
    this.showDeleteModal = true
  }

  async deleteBanner() {
    try {
      this.showDeleteModal = false
      this.showLoading({text: 'Saving...'})
      BannerService.deleteBanners(this.selectedDelete).then(response => {
        this.showSnackbar({
          text: 'Success',
          color: 'green',
          timeout: 1500
        })
        this.getBanner()
      }).catch(error => {
        this.showSnackbar({
          text: error.response.data.message,
          color: 'red',
          timeout: 1500
        })
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }
}
